import defaults, { baseState } from './utils'
import Api from '@/resources/Api'
import AuthHandler from '@/plugins/AuthHandler'
import CryptoJS from 'crypto-js'

const agents = {
    namespaced: true,
    state: baseState,
    mutations: {
        ...defaults.mutations,
        RESET_STATE (state) {
            Object.assign(state, baseState())
        }
    },
    getters: {
        ...defaults.getters,
        endpointUrl: (state) => ({ instance } = {}) => {
            if (!instance) return 'agents/'
            if (instance.is_current_user) return 'account/'
            return `agents/${instance.email}`
        },
        account: (state) => {
            return Object.values(state.items).find(x => x.is_current_user)
        },
        defaultInbox: (state, getters) => {
            if (getters.account?.custom_inboxes) {
                return getters.account.custom_inboxes[0]
            }
            return null
        },
        defaultInboxRoute: (state, getters) => {
            const customInboxes = getters.account?.custom_inboxes
            if (customInboxes?.length > 0) {
                return { name: 'conversations', query: customInboxes[0].query }
            }
            return { name: 'conversations', query: { assigned: 'me+none', is: 'opened', order: 'priority' } }
        },
        owner: (state) => {
            return Object.values(state.items).find(x => x.owner)
        },
        hasRole: (state, getters) => (role) => {
            const roles = getters.account?.roles
            if (!roles) {
                return false
            }
            if (role.endsWith('.*')) {
                const partialRole = role.substr(0, role.length - 1)
                for (let i = 0; i < roles.length; i++) {
                    if (roles[i].indexOf(partialRole) === 0) return true
                }
                return false
            }

            return roles.indexOf(role) > -1
        }
    },
    actions: {
        ...defaults.actions,
        async all ({ getters, commit }, complete = false) {
            let url = getters.endpointUrl()
            if (complete) url += '?complete=true'

            if (complete) {
                const result = await Api.get(url)
                result.data.forEach(agent => {
                    commit('UPDATE_ENTRY', { targetId: agent.id, document: agent })
                })
                return
            }
            commit('ON_LOADING_START')
            // Don't put this as async, or it will be stuck waiting for the rest
            try {
                await Api.stream(url)
            } catch (e) {
                if (e.response.status !== 401) {
                    alert('An error occured. Please refresh your browser.')
                }
            }
            commit('ON_LOADING_STOP')
        },
        async updateAccount ({ getters, commit }, document) {
            commit('UPDATE_ENTRY', { targetId: getters.account.id, document })
        },
        async accountNotifications ({ getters, commit }, document) {
            const account = getters.account
            await Api.put(getters.endpointUrl({ instance: account }) + 'notifications', document)
            commit('UPDATE_ENTRY', { targetId: account.id, document: { notifications: document } })
        },
        async registerPushEndpoint ({ getters }, subscription) {
            const account = getters.account
            await Api.post(getters.endpointUrl({ instance: account }) + 'notifications/register', subscription)
        },
        async unregisterPushEndpoint ({ getters }, subscription) {
            const account = getters.account
            await Api.post(getters.endpointUrl({ instance: account }) + 'notifications/unregister', subscription)
        },
        async remove ({ getters, commit }, { id, reassignId }) {
            await Api.delete(getters.endpointUrl({ instance: getters.get(id) }), { data: { reassign_id: reassignId } })
            commit('REMOVE_ENTRY', id)
        },
        async postAdd ({ getters, dispatch }, { id, document }) {
            const currentUser = getters.account
            if (currentUser?.id === id) {
                if ('email' in document) {
                    AuthHandler.setEmail(document.email)
                }

                await dispatch('statistics/load', { complete: true }, { root: true })
                dispatch('statistics/setDefaultInbox', currentUser.custom_inboxes?.length > 0 ? currentUser.custom_inboxes[0] : null, { root: true })
            }
        },
        async postUpdate ({ getters }, { id, document }) {
            if (getters.account?.id === id) {
                if ('email' in document) {
                    AuthHandler.setEmail(document.email)
                }

                /*
                // => Moved at `updateCustomInbox`
                if ('custom_inboxes' in document) {
                    await dispatch('statistics/load', {}, { root: true })
                    dispatch('statistics/setDefaultInbox', document.custom_inboxes.length > 0 ? document.custom_inboxes[0] : null, { root: true })
                }
                */
            }
        },
        live ({ commit }, { agent_id }) { /* eslint-disable-line camelcase */
            commit('UPDATE_ENTRY', {
                targetId: agent_id, /* eslint-disable-line camelcase */
                document: {
                    isConnected: true
                }
            })
        },
        left ({ commit }, { agent_id }) { /* eslint-disable-line camelcase */
            commit('UPDATE_ENTRY', {
                targetId: agent_id, /* eslint-disable-line camelcase */
                document: {
                    isConnected: false
                }
            })
        },
        async updateCustomInbox ({ getters, dispatch }, { key, inbox }) {
            const activeAccount = getters.account
            const inboxes = [...activeAccount.custom_inboxes]

            if (key == null) { // Add
                inbox.key = CryptoJS.MD5(Date.now() + JSON.stringify(inbox.query)).toString()
                inboxes.push(inbox)
            } else {
                const index = inboxes.findIndex(x => x.key === key)
                if (inbox == null) { // Remove
                    inboxes.splice(index, 1)
                } else { // Update
                    inbox.key = key
                    inboxes[index] = inbox
                }
            }

            await dispatch('update', {
                id: getters.account.id,
                document: {
                    custom_inboxes: inboxes
                }
            })

            await dispatch('statistics/load', {}, { root: true })
            dispatch('statistics/setDefaultInbox', inboxes.length > 0 ? inboxes[0] : null, { root: true })
        }
    }
}

export default agents
