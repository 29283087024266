import { createStore } from 'vuex'
import Api from '@/resources/Api'
import WebSocket from '@/resources/WebSocket'
import AuthHandler from '@/plugins/AuthHandler'
import Storage from '@/resources/Storage'
import models from '@/models'
import router from '@/router'

// Modules
import Statistics from './modules/Statistics'
import Messenger from './modules/Messenger'

const defaultEmojis = ['1f60e', '1f44d', '1f44e', '1f62d', '1f615', '1f60a', '1f60d', '1f923', '1f914', '1f609', '1f61b', '1f64c']

const store = createStore({
    state: {
        requests: {},
        terminateds: {},
        endpoints: {},
        frequentEmojis: [],
        linearProperties: {},
        lastInboxRoute: null,
        pendingUploads: 0
    },
    modules: {
        ...models,
        statistics: Statistics,
        messenger: Messenger
    },
    getters: {
        lastInboxRoute: state => state.lastInboxRoute,
        frequentsEmojis: state => state.frequentEmojis,
        linearProperties: state => state.linearProperties,
        getRequestPromise: state => url => url in state.endpoints ? state.endpoints[url] : null,
        getPendingUploads: state => state.pendingUploads
    },
    mutations: {
        SET_LAST_INBOX_ROUTE (state, route) {
            state.lastInboxRoute = route
        },
        SET_FREQUENT_EMOJIS (state, emojis) {
            state.frequentEmojis = emojis
        },
        SET_LINEAR_PROPERTIES (state, properties) {
            properties.priorities.sort((a, b) => a.id - b.id)
            state.linearProperties = properties
        },
        INCREMENT_PENDING_UPLOADS (state) {
            state.pendingUploads++
        },
        DECREMENT_PENDING_UPLOADS (state) {
            state.pendingUploads--
        }
    },
    actions: {
        connect (context, { token, slug = null }) {
            AuthHandler.connect(slug, token)
        },
        async load ({ dispatch }) {
            await dispatch('agents/all')
            await Promise.allSettled([
                dispatch('channels/allAsync'),
                dispatch('tags/allAsync'),
                dispatch('snippetCategories/allAsync'),
                dispatch('snippets/allAsync'),
                dispatch('loadFrequentEmojis')
            ])
        },
        async logout (contex, token) {
            /** logout : deletes the key session at the server */
            await Api.post('/auth/clear', { token })
            AuthHandler.logout()
            this.dispatch('disconnected', false)
        },
        async disconnected ({ dispatch }, invalidToken = true) {
            WebSocket.clear()
            if (router.currentRoute.value.meta?.requiresAuth !== false) {
                let query = {}
                if (invalidToken) {
                    AuthHandler.logout()
                    query = {
                        disconnected: true,
                        next: router.currentRoute.value.path
                    }
                }

                await router.push({ name: 'login', query })
            }

            dispatch('clear')
        },
        async clear (store) {
            const promises = []
            Object.keys(store.state).forEach(key => {
                const action = `${key}/clear`
                if (action in this._actions) {
                    promises.push(store.dispatch(action))
                }
            })
            return await Promise.allSettled(promises)
        },
        setRequestPromise ({ state, dispatch }, { url, promise }) {
            state.endpoints[url] = promise
        },
        onRequestStarted ({ state, dispatch }, { id, identifier, url, resolve, reject }) {
            if (id in state.terminateds) {
                // We already finished before processing !
                resolve(state.terminateds[id])
                delete state.terminateds[id]
                return
            }

            const timeout = setTimeout(() => {
                dispatch('onRequestFailure', { id })
            }, 30 * 1000) // Automatic failure after 30 seconds

            state.requests[id] = {
                resolve,
                reject,
                identifier,
                url,
                items: [],
                timeout
            }
        },
        onRequestComplete ({ state }, struct) {
            if (struct.target_id in state.requests) {
                clearTimeout(state.requests[struct.target_id].timeout)
                state.requests[struct.target_id].resolve(struct.document)
                if (state.requests[struct.target_id].url in state.endpoints) {
                    delete state.endpoints[state.requests[struct.target_id].url]
                }
                delete state.requests[struct.target_id]
            } else {
                // We might have received an ending BEFORE we set up the tracking
                state.terminateds[struct.target_id] = struct.document
            }
        },
        onRequestFailure ({ state }, { id, document = null }) {
            if (id in state.requests) {
                state.requests[id].reject(document)
                clearTimeout(state.requests[id].timeout)
                if (state.requests[id].url in state.endpoints) {
                    delete state.endpoints[state.requests[id].url]
                }
                delete state.endpoints[state.requests[id].url]
                delete state.requests[id]
            }
        },
        setLastInboxRoute ({ commit }, route) {
            commit('SET_LAST_INBOX_ROUTE', route)
        },
        loadFrequentEmojis ({ commit }) {
            let frequents = defaultEmojis
            try {
                const items = Storage.get('fernand.frequentEmoji')
                if (Array.isArray(items)) {
                    const limit = defaultEmojis.length
                    frequents = [...items.slice(0, limit), ...defaultEmojis.slice(0, limit - Math.min(items.length, limit))]
                }
            } catch (e) {}

            commit('SET_FREQUENT_EMOJIS', frequents)
        },
        selectEmoji ({ getters, commit }, emoji) {
            if (!('key' in emoji)) return

            const frequents = getters.frequentsEmojis
            const index = frequents.indexOf(emoji.key)
            if (index > -1) {
                // Present, we remove it
                frequents.splice(index, 1)
            }

            frequents.unshift(emoji.key)

            commit('SET_FREQUENT_EMOJIS', frequents.slice(0, defaultEmojis.length))

            // Then, we save for future needs:
            try {
                Storage.set('fernand.frequentEmoji', frequents)
            } catch (e) {}
        },
        async loadLinearProperties ({ getters, commit }, force = false) {
            if (Object.keys(getters.linearProperties).length > 0 && !force) return

            const result = await Api.get('/integrations/linear/properties')
            commit('SET_LINEAR_PROPERTIES', result.data)
        },
        incrementPendingUploads ({ commit }) {
            commit('INCREMENT_PENDING_UPLOADS')
        },
        decrementPendingUploads ({ commit }) {
            commit('DECREMENT_PENDING_UPLOADS')
        }
    }
})

export default store
